import React, { useEffect, useState } from 'react'
import { Container } from '@nextui-org/react'

import { Colors } from '@/constants/styles/color'

interface Props {
  tabs: string[]
  onChange?: (tabIndex: number) => any
  current?: number
}

const Tabs = ({ tabs, onChange, current }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(current !== undefined ? current : 1)

  useEffect(() => {
    if (current !== undefined) {
      setCurrentIndex(current)
    }
  }, [current])

  const onClickItem = (index: number) => {
    setCurrentIndex(index)
    if (onChange) {
      onChange(index)
    }
  }
  return (
    <>
      <Container
        data-testid="tabs"
        css={{
          d: 'flex',
          p: 0,
          maxWidth: '100%',
        }}
      >
        {tabs.map((tab, index) => {
          return (
            <Container
              key={index}
              css={{
                borderRadius: 0,
                bg: Colors.WHITE,
                color: Colors.BLACK,
                borderColor: currentIndex === index ? Colors.FANME_YELLOW : Colors.VERY_LIGHT_GRAY,
                borderBottomWidth: '4px',
                borderBottomStyle: 'solid',
                padding: 0,
                h: '48px',
                w: `calc(100% / ${tabs.length})`,
                minWidth: `calc(100% / ${tabs.length})`,
                fontStyle: 'normal',
                fontWeight: currentIndex === index ? '700' : '500',
                fs: '12px',
                lh: '12px',
                d: 'flex',
                ai: 'center',
                ta: 'center',
                jc: 'center',
                cursor: 'pointer',
              }}
              onClick={() => onClickItem(index)}
            >
              {tab}
            </Container>
          )
        })}
      </Container>
    </>
  )
}

export default Tabs
