import getConfig from 'next/config'
import { parseCookies } from 'nookies'

export function useShopApi() {
  const getToken = () => {
    const cookies = parseCookies()
    const tokenKey = process.env.NEXT_PUBLIC_TOKEN_KEY || 'fanme_token'
    return cookies[tokenKey]
  }

  const apiRequest = async (method: string, endpoint: string, withToken: boolean, body?: any) => {
    const { publicRuntimeConfig } = getConfig()
    const BASE_URL = publicRuntimeConfig.SHOP_API_URL
    const token = getToken()

    const headers: HeadersInit = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(withToken && { Authorization: `Bearer ${token}` }),
    }

    const options: RequestInit = {
      method,
      headers,
      body: body ? JSON.stringify(body) : undefined,
    }

    const response = await fetch(`${BASE_URL}${endpoint}`, options)
    const data = await response.json()
    if (!response.ok) {
      throw new Error(data.errors || 'API request failed')
    }
    return data
  }

  const get = (endpoint: string) => {
    return apiRequest('GET', endpoint, true)
  }

  const getWithoutAuth = (endpoint: string) => {
    return apiRequest('GET', endpoint, false)
  }

  const post = (endpoint: string, body: any) => {
    return apiRequest('POST', endpoint, true, body)
  }

  const put = (endpoint: string, body: any) => {
    return apiRequest('PUT', endpoint, true, body)
  }

  const del = (endpoint: string) => {
    return apiRequest('DELETE', endpoint, true)
  }

  return { get, getWithoutAuth, post, put, delete: del }
}
