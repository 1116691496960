import React from 'react'
import { Switch } from '@nextui-org/react'
import { Colors } from '@/constants/styles/color'

interface Props {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined
  checked?: boolean
  onChange?: (e: any) => void
  dark?: boolean
}

const ToggleSwitch = ({ size, checked, onChange, dark = false }: Props) => {
  return (
    <Switch
      data-testid="toggle-switch"
      size={!size ? 'lg' : size}
      css={{
        p: 0,
        '.nextui-switch--unchecked': {
          bg: dark ? Colors.BLACK : Colors.VERY_LIGHT_GRAY,
          '.nextui-switch-circle': {
            bg: dark ? Colors.DARK_GRAY : Colors.LIGHT_GRAY,
          },
        },
        '.nextui-switch-checked': {
          bg: Colors.FANME_YELLOW,
          '&:hover:not(.nextui-switch-checked:active)': {
            bg: Colors.FANME_YELLOW,
          },
          '.nextui-switch-circle': {
            bg: Colors.WHITE,
          },
        },
      }}
      checked={checked}
      onChange={onChange}
    />
  )
}

export default ToggleSwitch
