'use client'

import React, {
  useState,
  ReactNode,
  MouseEvent,
  useRef,
  forwardRef,
  useEffect,
  useMemo,
} from 'react'
import { clsx } from 'clsx'
import Image from 'next/image'
import { Button as NextUIButton } from '@nextui-org/react'

type ThemeButtonProps = {
  text: string
  width?: string
  height?: string
  textColor?: string
  bgColor?: string
  border?: string
  borderRadius?: string
  fontFamily?: string
  fontSize?: string
  fontWeight?: string
  lineHeight?: string
  letterSpacing?: string
  padding?: string

  icon?: React.ReactElement
  iconRight?: React.ReactElement

  disable?: boolean
  onClick?: () => void
}

// マイページのThemeによって色が変わるボタン
const ThemeButton = ({
  text,
  height,
  width,
  textColor,
  bgColor,
  border,
  borderRadius,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  icon,
  iconRight,
  letterSpacing,
  padding,
  disable,
  onClick,
}: ThemeButtonProps) => {
  return (
    <NextUIButton
      data-testid="button"
      css={{
        w: width,
        minWidth: width,
        h: height ?? '$12',
        br: borderRadius ?? '$sm',
        color: textColor,
        border,
        bgColor,
        fontFamily,
        fs: fontSize,
        fontWeight,
        lh: lineHeight,
        letterSpacing,
        p: padding ?? 0,
      }}
      iconRight={iconRight ?? ''}
      icon={icon ?? ''}
      disabled={disable}
      onClick={onClick}
    >
      {text}
    </NextUIButton>
  )
}

export default ThemeButton

export type ButtonType =
  | 'dark'
  | 'light'
  | 'disabled'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'dark-small'
  | 'light-small'
  | 'disabled-small'
  | 'pink'
type SideButtonType = 'dark' | 'light'
type ButtonSize = 'sm' | 'md' | 'lg' | 'extra' | 'free'
type CircleButtonSize = 'xxxxs' | 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg'
type ButtonShape = 'rounded' | 'circle' | 'square'
type ButtonDirection = 'left' | 'right'

// flat button
type FlatButtonColor = 'gray' | 'black' | 'transparent'
type FlatButtonShape = 'pill' | 'circle' | 'oval'
type FlatCircleButtonSize = 'xs' | 'md'
type FlatButtonType = 'edit' | 'preview' | 'close' | 'image'

interface IFlatButtonType {
  buttonColor: FlatButtonColor
  buttonShape: FlatButtonShape
  buttonType: FlatButtonType
  buttonSize?: FlatCircleButtonSize
  className?: string
  disabled?: boolean
  onClick?: () => void
}
interface ISideButtonType {
  dark: SideButtonType
  light: SideButtonType
}
interface IButtonType extends ISideButtonType {
  darkSmall: ButtonType
  lightSmall: ButtonType
  disabled: ButtonType
  disabledSmall: ButtonType
  primary: ButtonType
  secondary: ButtonType
  error: ButtonType
  pink: ButtonType
}

export const BUTTON_TYPES_CLASSES: IButtonType = {
  dark: 'dark',
  darkSmall: 'dark-small',
  light: 'light',
  lightSmall: 'light-small',
  disabled: 'disabled',
  disabledSmall: 'disabled-small',
  primary: 'primary',
  secondary: 'secondary',
  error: 'error',
  pink: 'pink',
}

export const SIDE_BUTTON_TYPES_CLASSES: ISideButtonType = {
  dark: 'dark',
  light: 'light',
}
// 3d button
interface IButton {
  children: ReactNode
  buttonType?: ButtonType
  sideButtonType?: SideButtonType
  buttonDirection?: ButtonDirection
  buttonSize?: ButtonSize | CircleButtonSize
  buttonShape?: ButtonShape
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
  buttonClassNames?: string
  type?: 'button' | 'submit' | 'reset'
}
type getButtonCls = (button?: ButtonType, disabled?: boolean) => string
type getButtonShapeCls = (buttonShape?: ButtonShape) => string
type getButtonSizeCls = (
  buttonSize?: ButtonSize | CircleButtonSize,
  buttonShape?: ButtonShape,
) => string
type getSideButtonCls = (buttonType?: SideButtonType) => string
type getButtonBgCls = (buttonType?: SideButtonType) => string
type getSideButtonSizeCls = (buttonSize?: ButtonSize) => string
type getButtonSizeInnerCls = (buttonSize?: ButtonSize) => string
type getSideButtonDirectionCls = (
  buttonDirection?: ButtonDirection,
  buttonSize?: ButtonSize,
) => string
type getButtonIcon = (
  buttonType: FlatButtonType,
  buttonShape: FlatButtonShape,
  buttonSize?: FlatCircleButtonSize,
  disabled?: boolean,
) => ReactNode

const getButtonCls: getButtonCls = (buttonType = BUTTON_TYPES_CLASSES.dark, disabled) => {
  if (disabled) {
    if (buttonType.includes('small')) {
      return 'shadow-disabled bg-gray-200 border-gray-100 text-white flex justify-center items-center'
    }
    return 'shadow-disabled bg-gray-200 border-gray-100 text-white active:shadow-disabled-none'
  }
  return {
    [BUTTON_TYPES_CLASSES.dark]:
      'shadow-dark bg-gray-800 border-gray-600 text-white active:shadow-dark-none',
    [BUTTON_TYPES_CLASSES.light]:
      'shadow-light bg-white border-navy-50 text-gray-800 active:shadow-light-none',
    [BUTTON_TYPES_CLASSES.disabled]: 'shadow-disabled bg-gray-200 border-gray-100 text-white',
    [BUTTON_TYPES_CLASSES.primary]:
      'shadow-main bg-primary border-yellow-100 text-gray-800 active:shadow-main-none',
    [BUTTON_TYPES_CLASSES.secondary]:
      'shadow-sub bg-orange-100 border-orange-50 text-gray-800 active:shadow-sub-none',
    [BUTTON_TYPES_CLASSES.error]:
      'shadow-err bg-white border-error text-pink-200 active:shadow-err-none',
    [BUTTON_TYPES_CLASSES.darkSmall]:
      'bg-gray-800 border-gray-600 text-white flex justify-center items-center',
    [BUTTON_TYPES_CLASSES.lightSmall]:
      'bg-white border-navy-50 text-gray-800 flex justify-center items-center',
    [BUTTON_TYPES_CLASSES.disabledSmall]:
      'bg-gray-200 border-gray-100 text-white flex justify-center items-center',
    [BUTTON_TYPES_CLASSES.pink]:
      'shadow-pink bg-pink-100 border-pink-50 text-white flex justify-center items-center',
  }[buttonType]
}

const getSideButtonCls: getSideButtonCls = (buttonType = SIDE_BUTTON_TYPES_CLASSES.dark) =>
  ({
    [SIDE_BUTTON_TYPES_CLASSES.dark]:
      'shadow-dark bg-gray-700 border-gray-600 active:shadow-dark-none',
    [SIDE_BUTTON_TYPES_CLASSES.light]:
      'shadow-light bg-white border-navy-50 active:shadow-light-none',
  }[buttonType])
const getButtonBgCls: getButtonBgCls = (buttonType = SIDE_BUTTON_TYPES_CLASSES.dark) =>
  ({
    [SIDE_BUTTON_TYPES_CLASSES.dark]: 'bg-white',
    [SIDE_BUTTON_TYPES_CLASSES.light]: 'bg-navy-50',
  }[buttonType])

const getSideButtonDirectionCls: getSideButtonDirectionCls = (
  buttonDirection = 'left',
  buttonSize = 'md',
) =>
  ({
    left: buttonSize === 'md' ? 'rounded-l-5xl' : 'rounded-l-6xl',
    right: buttonSize === 'md' ? 'rounded-r-5xl' : 'rounded-r-6xl',
  }[buttonDirection])

const getButtonShapeCls: getButtonShapeCls = (buttonShape = 'rounded') =>
  ({
    rounded: 'rounded-5xl',
    circle: 'rounded-full',
    square: 'rounded-2xl',
  }[buttonShape])

const getButtonSizeCls: getButtonSizeCls = (buttonSize = 'lg', buttonShape = 'rounded') => {
  switch (buttonShape) {
    case 'rounded':
      return {
        sm: 'w-14 h-6 border text-medium-11',
        md: 'w-24 h-8 border text-medium-14',
        lg: 'w-81 h-12 border-2 text-bold-16',
        extra: 'w-74 h-12 border-2 text-bold-16',
        free: '',
      }[buttonSize as ButtonSize]
    case 'circle':
      return {
        xxxxs: 'w-3.75 h-3.75 border text-medium-11',
        xxxs: 'w-5.5 h-5.5 border text-medium-11',
        xxs: 'w-6.5 h-6.5 border text-medium-11',
        xs: 'w-7 h-7 border text-medium-11',
        sm: 'w-8 h-8 border text-medium-11',
        md: 'w-10 h-10 border text-medium-14',
        lg: 'w-11 h-11 border text-bold-16',
      }[buttonSize as CircleButtonSize]
    case 'square':
      return 'w-22 h-20 border text-medium-15'
  }
}

const getSideButtonSizeCls: getSideButtonSizeCls = (buttonSize = 'lg') =>
  ({
    lg: 'w-15 h-15',
    md: 'w-13 h-13',
    sm: '',
    extra: '',
    free: '',
  }[buttonSize])

const getButtonSizeInnerCls: getButtonSizeInnerCls = (buttonSize = 'lg') =>
  ({
    lg: 'w-12.5 h-12.5',
    md: 'w-11 h-11',
    sm: '',
    extra: '',
    free: '',
  }[buttonSize])

// flat button
const getButtonIcon = (
  buttonType: FlatButtonType,
  buttonShape: FlatButtonShape,
  buttonSize?: FlatCircleButtonSize,
  disabled?: boolean,
) => {
  switch (buttonType) {
    case 'edit': {
      const iconSize = buttonShape === 'circle' ? (buttonSize === 'xs' ? 16 : 22) : 12
      return (
        <Image src="/images/icons/PageEdit.svg" width={iconSize} height={iconSize} alt="edit" />
      )
    }
    case 'preview':
      return (
        <Image
          src="/images/icons/PassDisplay.svg"
          width={22}
          height={22}
          alt={disabled ? 'preview-off' : 'preview-on'}
        />
      )
    case 'close':
      return <Image src="/images/icons/Close.svg" width={16} height={16} alt="close" />
    case 'image':
      return (
        <Image
          src="/images/icons/PhotoIcn.svg"
          width={22}
          height={22}
          alt="help"
          className="brightness-1000"
        />
      )
  }
}
type getFlatButtonCls = (buttonColor: FlatButtonColor) => string
type getFlatButtonSizeCls = (
  buttonShape: FlatButtonShape,
  buttonSize?: FlatCircleButtonSize,
) => string
const getFlatButtonCls: getFlatButtonCls = buttonColor => {
  switch (buttonColor) {
    case 'gray':
      return 'bg-gray-200 border-gray-100'
    case 'black':
      return 'bg-gray-700 border-gray-600'
    case 'transparent':
      return 'bg-transparent-white border-white'
  }
}
const getFlatButtonSizeCls: getFlatButtonSizeCls = (buttonShape, buttonSize = 'xs') => {
  switch (buttonShape) {
    case 'pill':
      return 'w-12 h-6'
    case 'circle':
      return buttonSize == 'md' ? 'w-11 h-11' : 'w-8 h-8'
    case 'oval':
      return 'w-12 h-9'
  }
}

const getButtonHeight = (buttonSize: ButtonSize | CircleButtonSize, buttonShape: ButtonShape) => {
  if (buttonShape === 'square') return 'h-22'
  switch (buttonSize) {
    case 'xxxxs':
      // 16px
      return 'h-4'
    case 'xxxs':
      // 24px
      return 'h-6'
    case 'xxs':
      // 30px
      return 'h-7.5'
    case 'xs':
      // 32px
      return 'h-8'
    case 'sm':
      // shape: circle and rounded only
      // circle: 34px
      // rounded: 28px
      return buttonShape === 'circle' ? 'h-8.5' : 'h-7'
    case 'md':
      // circle: 42px
      // rounded: 40px
      return buttonShape === 'circle' ? 'h-10.5' : 'h-10'
    case 'lg':
      // circle: 46px
      // rounded: 56px
      return buttonShape === 'circle' ? 'h-11.5' : 'h-14'
    default:
      return buttonShape === 'circle' ? 'h-11.5' : 'h-14'
  }
}
const Button = ({
  children,
  buttonType,
  buttonSize,
  buttonShape,
  onClick,
  buttonClassNames,
  disabled,
  ...otherProps
}: IButton) => {
  const [buttonLoading, setButtonLoading] = useState(false)
  useEffect(() => {
    setButtonLoading(!!disabled)
  }, [disabled])
  const handleClick = async (event: MouseEvent<HTMLButtonElement>) => {
    if (otherProps.type !== 'submit') {
      event.preventDefault()
    }
    if (onClick) {
      setButtonLoading(true)
      await onClick(event)
      setButtonLoading(false)
    }
  }
  const buttonCls = getButtonCls(buttonType, buttonLoading)
  const buttonShapeCls = getButtonShapeCls(buttonShape)
  const buttonHeight = getButtonHeight(
    buttonSize as ButtonSize | CircleButtonSize,
    buttonShape as ButtonShape,
  )
  const buttonSizeCls =
    buttonSize !== 'free' ? getButtonSizeCls(buttonSize, buttonShape) : buttonClassNames

  const noMarginTypes = [
    BUTTON_TYPES_CLASSES.darkSmall,
    BUTTON_TYPES_CLASSES.lightSmall,
    BUTTON_TYPES_CLASSES.disabled,
    BUTTON_TYPES_CLASSES.disabledSmall,
  ]

  const buttonClasses = useMemo(() => {
    // 非活性ボタン
    if (
      buttonLoading ||
      buttonType === BUTTON_TYPES_CLASSES.disabled ||
      buttonType === BUTTON_TYPES_CLASSES.disabledSmall
    ) {
      return 'cursor-default hover:brightness-100'
    }
    // ダークボタン
    if (buttonType === BUTTON_TYPES_CLASSES.dark || buttonType === BUTTON_TYPES_CLASSES.darkSmall) {
      return 'hover:brightness-125'
    }
    // 他のボタン
    return 'hover:brightness-98'
  }, [buttonLoading, buttonType])

  // 動くボタンのマージン
  const marginClass =
    noMarginTypes.includes(buttonType as ButtonType) || buttonLoading
      ? 'active:mt-0' // 小さいボタン動かない
      : 'active:mt-1' // 通常ボタン動く

  // 最終的なボタンクラス
  const finalButtonClasses = `${buttonClasses} ${marginClass}`
  return (
    <div className={clsx(buttonHeight, otherProps.className)}>
      <button
        {...otherProps}
        disabled={buttonLoading}
        className={clsx(
          'flex select-none items-center justify-center transition-all duration-75',
          finalButtonClasses,
          buttonCls,
          buttonShapeCls,
          buttonSizeCls,
        )}
        onClick={handleClick}
      >
        {children}
      </button>
    </div>
  )
}

// eslint-disable-next-line react/display-name
const SideButton = forwardRef<HTMLDivElement, IButton>(
  (
    {
      children,
      sideButtonType,
      buttonDirection,
      buttonSize,
      onClick,
      className,
      ...otherProps
    }: IButton,
    ref: React.LegacyRef<HTMLDivElement> | undefined,
  ) => {
    const buttonCls = getSideButtonCls(sideButtonType)
    const buttonTypeCls = getSideButtonDirectionCls(buttonDirection, buttonSize as ButtonSize)
    const buttonSizeCls = getSideButtonSizeCls(buttonSize as ButtonSize)
    const bgCls = getButtonBgCls(sideButtonType)
    const innerSizeCls = getButtonSizeInnerCls(buttonSize as ButtonSize)
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      if (onClick) {
        onClick(event)
      }
    }
    return (
      <div className="h-auto" ref={ref}>
        <button
          {...otherProps}
          onClick={handleClick}
          className={clsx(
            'flex items-center justify-center border active:mt-1',
            buttonCls,
            buttonSizeCls,
            buttonTypeCls,
            className,
          )}
        >
          <div
            className={clsx(
              'relative flex items-center justify-center rounded-full',
              bgCls,
              innerSizeCls,
            )}
          >
            {children}
          </div>
        </button>
      </div>
    )
  },
)

const OutlinedButton = ({
  buttonColor,
  buttonShape,
  buttonType,
  buttonSize,
  onClick,
  disabled,
  className,
  ...otherProps
}: IFlatButtonType) => {
  const buttonCls = getFlatButtonCls(buttonColor)
  const buttonSizeCls = getFlatButtonSizeCls(buttonShape, buttonSize)
  const buttonIcon = getButtonIcon(buttonType, buttonShape, buttonSize, disabled)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (onClick) {
      onClick()
    }
  }
  return (
    <button
      {...otherProps}
      disabled={disabled}
      className={clsx(
        'flex shrink-0 items-center justify-center rounded-full border-2',
        buttonColor === 'black' && !disabled ? 'hover:brightness-125 active:brightness-150' : null,
        buttonCls,
        buttonSizeCls,
        className,
      )}
      onClick={handleClick}
    >
      {buttonIcon}
    </button>
  )
}

type NoBackgroundButtonSize = 'xs' | 'sm' | 'md' | 'lg'
type NoBackgroundButtonColor = 'primary' | 'gray' | 'white' | 'red'

interface INoBackgroundButton {
  children?: ReactNode
  icon?: string
  iconSize?: number
  buttonSize?: NoBackgroundButtonSize
  buttonColor?: NoBackgroundButtonColor
  disabled?: boolean
  onClick?: () => void
  className?: string
  type?: 'button' | 'submit' | 'reset'
}

const getNoBackgroundButtonSizeCls = (buttonSize: NoBackgroundButtonSize = 'md') => {
  return {
    xs: 'text-medium-11 gap-1',
    sm: 'text-medium-14 gap-1',
    md: 'text-medium-16 gap-1',
    lg: 'text-bold-18 gap-1',
  }[buttonSize]
}

const getNoBackgroundButtonColorCls = (
  buttonColor: NoBackgroundButtonColor = 'gray',
  disabled = false,
) => {
  if (disabled) return 'cursor-not-allowed text-gray-400'

  return {
    primary: 'text-primary hover:text-primary/80 active:text-primary/60',
    gray: 'text-gray-800 hover:text-gray-600 active:text-gray-400',
    white: 'text-white hover:text-white/80 active:text-white/60',
    red: 'text-error hover:text-error/80 active:text-error/60',
  }[buttonColor]
}

const NoBackgroundButton = ({
  children,
  icon,
  iconSize,
  buttonSize = 'md',
  buttonColor = 'gray',
  disabled,
  onClick,
  className,
  ...otherProps
}: INoBackgroundButton) => {
  const buttonSizeCls = getNoBackgroundButtonSizeCls(buttonSize)
  const colorCls = getNoBackgroundButtonColorCls(buttonColor, disabled)
  const defaultIconSize =
    iconSize ||
    (buttonSize === 'lg' ? 24 : buttonSize === 'md' ? 20 : buttonSize === 'sm' ? 16 : 14)

  return (
    <button
      {...otherProps}
      disabled={disabled}
      onClick={onClick}
      className={clsx(
        'flex items-center justify-center transition-all',
        buttonSizeCls,
        colorCls,
        className,
        'gap-1',
        'border-none bg-transparent',
      )}
    >
      {icon && (
        <Image
          src={icon}
          width={defaultIconSize}
          height={defaultIconSize}
          alt=""
          className={disabled ? 'opacity-40' : ''}
        />
      )}
      {children}
    </button>
  )
}

interface IRippleButton {
  children: ReactNode
  className?: string
  onClick?: (e: React.MouseEvent) => void
  disabled?: boolean
}
const RippleButton = ({ children, className, onClick, disabled }: IRippleButton) => {
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleClick = (e: React.MouseEvent) => {
    const button = buttonRef.current
    if (!button) return

    const rect = button.getBoundingClientRect()
    const size = Math.max(rect.width, rect.height)
    const x = e.clientX - rect.left - size / 2
    const y = e.clientY - rect.top - size / 2

    const ripple = document.createElement('span')
    ripple.classList.add('ripple')
    ripple.style.width = `${size}px`
    ripple.style.height = `${size}px`
    ripple.style.left = `${x}px`
    ripple.style.top = `${y}px`

    button.appendChild(ripple)

    // Remove the ripple element after the animation ends
    ripple.addEventListener('animationend', () => {
      ripple.remove()
    })

    if (onClick) {
      onClick(e)
    }
  }

  return (
    <button
      type="button"
      ref={buttonRef}
      disabled={disabled}
      className={clsx('ripple-button', className)}
      onClick={handleClick}
    >
      {children}
    </button>
  )
}

export { Button, SideButton, OutlinedButton, NoBackgroundButton, RippleButton }
