import styled from '@emotion/styled'
import { Colors, Gradation } from '@/constants/styles/color'

const StyledMyMenuHeader = styled.div`
  height: 72px;
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
  background: ${Colors.PRIMARY_GRAY};
`

const StyledMyMenuHeaderGrid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 40px 1fr;
`

const StyledMyMenuNonAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${Colors.LIGHT_NAVY};
  width: 40px;
  height: 40px;
  padding: 0;
`

const StyledMyMenuAvatar = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
`

const StyledMyMenuCreatorStatus = styled.div`
  margin: 0 0 0 8px;
`

const StyledMyMenuCreatorName = styled.div`
  display: flex;
  align-items: center;
  min-height: 18px;
  margin-bottom: 6px;
  p {
    margin: 0;
    font-size: 13px;
    font-weight: 700;
    line-height: 13px;
    color: ${Colors.WHITE};
    letter-spacing: 0em;
    overflow-wrap: break-word;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
`

const StyledMyMenuAccountIdentity = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: ${Colors.GRAY};
`

const StyledMyMenuClose = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: ${Colors.WHITE};
`

const StyledMyMenuContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  padding: 16px 0 24px;
  gap: 24px;
`

const StyledMyMenuWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
`

const StyledMyMenuBorderLine = styled.div<{ isOpen: boolean }>`
  display: flex;
  position: relative;
  justify-content: center;
  max-width: 338px;
  margin: 24px auto 0;
  background-color: white;
  padding: ${({ isOpen }) => (isOpen ? '16px 16px 0' : '16px')};
  flex-direction: ${({ isOpen }) => (isOpen ? 'column' : 'row')};
  border-radius: 16px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 16px;
    padding: 1px;
    background: ${Gradation.LOGO_Clr2_45}
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`

const StyledMyMenuCreatorSettings = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const StyledMyMenuAccordionButton = styled.div`
  position: relative;
  min-width: 24px;
  min-height: 24px;
  width: 24px;
  height: 24px;
  padding-bottom: 3px;
  border-radius: 50%;
  background: ${Colors.BLACK};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const StyledMyMenuCheckmark = styled.span<{ isOpen: boolean }>`
  position: absolute;
  top: ${({ isOpen }) => (isOpen ? '5px' : '9px')};
  width: 9px;
  height: 9px;
  border-right: 1px solid ${Colors.WHITE};
  border-bottom: 1px solid ${Colors.WHITE};
  transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(-135deg)')};
  transition: transform 0.2s;
`

const StyledMyMenuItem = styled.div<{
  width?: string
  height?: string
  flexDirectionRow?: boolean
}>`
  width: ${({ width }) => (width ? width : '152px')};
  height: ${({ height }) => (height ? height : '64px')};
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ flexDirectionRow }) => (flexDirectionRow ? flexDirectionRow : 'column')};
  -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.08));
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.08));
  border-radius: 8px;
  background: ${Colors.WHITE};
  gap: 8px;
`

const StyledMyMenuItemName = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  line-height: 11px;
  color: ${props => (props.color ? props.color : Colors.PRIMARY_GRAY)};
`

const StyledMyMenuNotification = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  left: calc(50% + 16px);
  background: ${Colors.RED};
`

const StyledContentHeader = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.PRIMARY_GRAY};
  position: relative;
`

const StyledContentHeaderCancel = styled.div`
  color: ${Colors.WHITE};
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  left: 16px;
`

const StyledContentHeaderTitle = styled.div`
  color: ${Colors.WHITE};
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
`

const StyledContentHeaderBtn = styled.button<{ disabled?: boolean }>`
  color: ${props => (props.disabled ? Colors.LIGHT_GRAY : Colors.PRIMARY_GRAY)};
  background: ${props => (props.disabled ? Colors.GRAY : Colors.FANME_YELLOW)};
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  width: 64px;
  height: 32px;
  border: none;
  border-radius: 16px;
  position: absolute;
  right: 12px;
`

const StyledContentContainer = styled.div`
  width: 100%;
  text-align: left;
`

const StyledContentRow = styled.div`
  position: relative;
`

const StyledFormLabel = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    color: ${Colors.PRIMARY_GRAY};
  }
`

const StyledFormTitle = styled.div`
  font-size: 11px;
  font-weight: 500;
  line-height: 11px;
  color: ${Colors.PRIMARY_GRAY};
`

const StyledFormRequired = styled.span`
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  margin-left: 4px;
  &:after {
    content: '*必須';
    color: ${Colors.ORANGE};
  }
`

const StyledFormNonRequired = styled.span`
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  margin-left: 4px;
  &:after {
    content: '*未入力もOK';
    color: ${Colors.GRAY};
  }
`

const StyledFormErrMsg = styled.p`
  margin: 4px 0 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 160%;
  color: ${Colors.RED};
`

const StyledFormNote = styled.p`
  margin: 4px 0 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 160%;
  color: ${Colors.GRAY};
`

const StyledSpacer = styled.div`
  padding: 8px;
`

export const StyledSettingButton = styled.button<{
  isDark?: boolean
  width?: string
  height?: string
  fontSize?: string
  fontWeight?: string
  borderRadius?: string
  margin?: string
  isResponsive?: boolean
}>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '56px')};
  margin: ${({ margin }) => (margin ? margin : '18px 0 20px')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '700')};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '28px')};
  background-color: ${({ isDark }) => (isDark ? Colors.WHITE : Colors.PRIMARY_GRAY)};
  color: ${({ isDark }) => (isDark ? Colors.PRIMARY_GRAY : Colors.WHITE)};
  cursor: pointer;
  border: none;

  &:hover {
    opacity: 0.8;
  }

  ${({ isResponsive }) =>
    isResponsive &&
    `
    @supports (width: clamp(160px, 10vw + 120px, 180px)) {
      width: clamp(160px, 10vw + 120px, 180px);
    }

    @supports not (width: clamp(160px, 10vw + 120px, 180px)) {
      @media (max-width: 480px) and (min-width: 376px) {
        width: calc(160px + (180px - 160px) * ((100vw - 375px) / (480px - 375px)));
      }
      @media (max-width: 375px) {
        width: 160px;
      }
    }
  `}
`

export {
  StyledMyMenuHeader,
  StyledMyMenuHeaderGrid,
  StyledMyMenuNonAvatar,
  StyledMyMenuAvatar,
  StyledMyMenuCreatorStatus,
  StyledMyMenuCreatorName,
  StyledMyMenuAccountIdentity,
  StyledMyMenuClose,
  StyledMyMenuContainer,
  StyledMyMenuWrapper,
  StyledMyMenuCheckmark,
  StyledMyMenuBorderLine,
  StyledMyMenuAccordionButton,
  StyledMyMenuItem,
  StyledMyMenuCreatorSettings,
  StyledMyMenuItemName,
  StyledMyMenuNotification,
  StyledContentHeader,
  StyledContentHeaderCancel,
  StyledContentHeaderTitle,
  StyledContentHeaderBtn,
  StyledContentContainer,
  StyledContentRow,
  StyledFormLabel,
  StyledFormTitle,
  StyledFormRequired,
  StyledFormNonRequired,
  StyledFormErrMsg,
  StyledFormNote,
  StyledSpacer,
}
